import accessibleAutocomplete from 'accessible-autocomplete'
import AddressLookup from '../../address_autocomplete/address_lookup.js'
import {Controller} from "@hotwired/stimulus"
import {showHide} from "../../govuk/visibility.js"
import {debounce} from "../debounce.js"

export default class extends Controller {
  #addressLookup
  #autocompleteInput
  #searching
  #debouncing = false

  // Mappings from source address provider functions to rails-like attr method names
  ATTRIBUTE_MAPPINGS = {
    'line1':      'line_1',
    'line2':      'line_2',
    'townOrCity': 'town_or_city',
    'county':     'county',
    'postcode':   'postcode',
    'country':    'country'
  }

  static targets = ['autocompleteRoot', 'visibleSearching', 'visibleManual']
  static values  = {
    noResults: String,
    searching: String,
    prefix: String,
    debounceMs: { type: Number, default: 200 },
    country: { type: String, default: 'UK' }
  }

  connect() {
    this._id = this.firstInputElement.id

    this.handleQueryDebounced = debounce(async (query, populateResults) => {
      await this.handleQuery(query, populateResults)
    }, this.debounceMsValue)

    this.debouncedSource = async (query, populateResults) => {
      this.#debouncing = true
      try {
        await this.handleQueryDebounced(query, populateResults)
      } finally {
        this.#debouncing = false
      }
    }

    accessibleAutocomplete({
      element: this.autocompleteRootTarget,
      id: this._id, // To match it to the existing <label>.
      tNoResults: () => this.#debouncing ? this.searchingValue : this.noResultsValue,
      source: this.debouncedSource.bind(this),
      onConfirm: this.addressChanged.bind(this)
    })

    this.searching = this.shouldStartInSearchMode()
  }

  toggleSearching(e) {
    e.preventDefault()
    this.searching = !this.searching
  }

  get searching() {
    return this.#searching
  }

  set searching(value) {
    this.#searching = value

    this.searching ?  this.hideAddressFields() : this.showAddressFields()
    this.setVisibilities()
  }

  showAddressFields() {
    this.autocompleteInput.id = null
  }

  hideAddressFields() {
    this.autocompleteInput.id = this._id
    this.autocompleteInput.value = null
  }

  get suggestions() {
    return this._suggestions
  }

  set suggestions(value) {
    this._suggestions = value
  }

  // The autocomplete input itself, once created
  get autocompleteInput() {
    if(!this.#autocompleteInput) {
      this.#autocompleteInput = this.autocompleteRootTarget.querySelector('input.autocomplete__input')
    }

    return this.#autocompleteInput
  }

  get addressLookup() {
    if (this.#addressLookup === undefined) {
      this.#addressLookup = new AddressLookup(this.countryValue)
    }

    return this.#addressLookup
  }

  invalidateAddressLookup() {
    this.#addressLookup = undefined
    // Stimulus can call this via countryValueChanged() before connect().
    // There isn't an autocompleteInput with a value to clear at that time.
    if(this.autocompleteInput) {
      this.autocompleteInput.value = ''
    }
  }

  async handleQuery(query, populateResults) {
    this.suggestions = await this.addressLookup.fetchSuggestions(query)
    const addresses = this.suggestions.map(suggestion => suggestion.displayName.toString())
    populateResults(addresses)
  }

  // Return a full address record
  async addressChanged(key) {
    if(key === undefined) {
      return
    }

    const suggestion = this.suggestions.find(suggestion => suggestion.displayName === key )

    this.completedAddress = await this.addressLookup.fetchResolvedAddress(suggestion.id)
    this.searching = false
  }

  setVisibilities() {
    this.visibleSearchingTargets.forEach(el => showHide(el, this.searching))
    this.visibleManualTargets.forEach(el => showHide(el, !this.searching))
  }


  get firstInputElement() {
    return this.element.querySelector(`#membership_application_${this.prefix}address_line_1`)
  }

  get prefix() {
    if (this.hasPrefixValue && this.prefixValue !== '') {
      return `${this.prefixValue}_`
    } else {
      return ''
    }
  }

  countryValueChanged() {
    this.invalidateAddressLookup()
  }

  set completedAddress(address) {
    this.clearErrorMessages()
    // Normally happens after this when setting searching to false, but must happen now to avoid field confusion setting line_1
    this.searching = false
    this.copyAddressValuesToFields(address)
  }

  // Determines whether the search form or the result form fields should be visible when loaded
  shouldStartInSearchMode() {
    return this.allFieldsEmpty() && this.noErrorMessages()
  }

  // Multi-field-specific methods follow here
  allFieldsEmpty() {
    const empty = (input) => input.value.trim().length === 0
    return Array.from(this.element.querySelectorAll('input')).every(input => empty(input))
  }

  noErrorMessages() {
    return !this.element.querySelector('.govuk-error-message')
  }

  clearErrorMessages() {
    Array.from(this.element.querySelectorAll('.govuk-error-message')).forEach(message => message.style.display = 'none')
  }

  copyAddressValuesToFields(address) {
    for(let source in this.ATTRIBUTE_MAPPINGS) {
      const attr = this.ATTRIBUTE_MAPPINGS[source]
      const id = `#membership_application_${this.prefix}address_${attr}`
      this.setInputValue(id, address[source])
    }
  }

  setInputValue(id, value) {
    const input = this.element.querySelector(id)
    if(input === null) return

    input.value = value
  }
}
